// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SSILoadingPage_captionContainer__YVy5u {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
}

.SSILoadingPage_caption__f9S3O {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.SSILoadingPage_description__InwXw {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.SSILoadingPage_rightPanel__SYJnl {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
}

.SSILoadingPage_contentContainer__bevRH {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/SSILoadingPage/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".captionContainer {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    gap: 8px;\n}\n\n.caption {\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 32px;\n}\n\n.description {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.rightPanel {\n    display: flex;\n    flex-grow: 1;\n    align-items: center;\n    flex-direction: column;\n}\n\n.contentContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 40px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"captionContainer": `SSILoadingPage_captionContainer__YVy5u`,
	"caption": `SSILoadingPage_caption__f9S3O`,
	"description": `SSILoadingPage_description__InwXw`,
	"rightPanel": `SSILoadingPage_rightPanel__SYJnl`,
	"contentContainer": `SSILoadingPage_contentContainer__bevRH`
};
export default ___CSS_LOADER_EXPORT___;
